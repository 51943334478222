import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';

import { LoginResult } from '../../components/login/login-result';
import { SESSION_ID_KEY } from '../../config/constants';
import { ConfigToken, EnvConfig } from '../../config/env.config';
import { SessionAuth } from '../../models/auth/session-auth';
import { getCookie } from '../../util/utility-functions';
import { StorageService } from '../storage/storage.service';

@Injectable()
export class AuthService {
  AUTH_URL = 'api/v1/instruments/verifications/{sessionId}/initiate';

  config: EnvConfig;
  sessionAuth: SessionAuth | undefined;

  constructor(private http: HttpClient,
              @Inject(ConfigToken) config: EnvConfig,
              private storageService: StorageService) {
    this.config = config;
  }

  isAuthenticated(): boolean {
    return this.containUserCookies() || this.sessionAuth !== undefined;
  }

  authenticate(sessionId: string | null, secureCode?: string): Observable<LoginResult> {
    if (secureCode && sessionId) {
      this.sessionAuth = undefined;
      return this.executeAuthentication(sessionId, secureCode);
    }

    if (this.isAuthenticated()) {
      return of(LoginResult.SUCCESS);
    }

    return of(LoginResult.FAILURE);
  }

  private executeAuthentication(sessionId: string, secureCode: string): Observable<LoginResult> {
    const params = new HttpParams()
      .set('secureCodeHash', secureCode);

    return this.http.get<SessionAuth>(this.getAuthEndpoint(sessionId), { params })
      .pipe(
        map((response: SessionAuth) => {
          this.sessionAuth = response;
          this.storageService.setItem(SESSION_ID_KEY, sessionId);
          return LoginResult.SUCCESS;
        })
      );
  }

  private containUserCookies(): boolean {
    return getCookie('XSRF-TOKEN') !== '';
  }

  private getAuthEndpoint(sessionId: string): string {
    return `${this.config.BACKEND_URL}${this.AUTH_URL.replace('{sessionId}', sessionId)}`;
  }

  getXsrfToken(): string | undefined {
    return this.sessionAuth?.xsrfToken;
  }

  getAccessToken(): string | undefined {
    return this.sessionAuth?.accessToken;
  }
}
