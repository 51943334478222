import { InjectionToken } from '@angular/core';
import { OptimizelyConfig } from 'paysafe-wallet-feature-management-lib-fe';

import { environment } from '../../environments/environment';

export interface EnvConfig {
  [key: string]: any;
  BACKEND_URL: string;
  PLAID_ENVIRONMENT: string;
  OPTIMIZELY_FEATURE_MANAGEMENT: OptimizelyConfig;
}

export const Config: EnvConfig = environment;
export const ConfigToken = new InjectionToken<any>('config');
