import { Component, Inject, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { InstrumentVerification } from '../../models/plaid/instrument-verification/plaid-instrument-verification';
import {
  InstrumentVerificationStatus
} from '../../models/plaid/instrument-verification/plaid-instrument-verification-status';
import { PlaidService } from '../../service/plaid/plaid.service';
import { StorageService } from '../../service/storage/storage.service';


@Component({
  selector: 'app-processing-screen',
  templateUrl: './processing-screen.component.html',
  styleUrls: ['./processing-screen.component.scss']
})
export class ProcessingScreenComponent implements OnInit {
  private readonly destroy$ = new Subject<void>();
  callingInterval = 5000; // // Delay between subsequent backend requests (in milliseconds)
  redirectUrl?: string;

  constructor(
    @Inject('Window') private readonly window: Window,
    private readonly plaidService: PlaidService,
    private readonly storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.checkSessionStatus();
  }

  checkSessionStatus(): void {
    this.plaidService.getInstrumentVerificationDetails().subscribe(
      response => {
        // Check if the desired session status is received
        if (this.isSessionFinalStatus(response)) {
          this.storageService.clear();
          if (this.redirectUrl) {
            this.window.location.href = this.redirectUrl;
          }
        } else {
          // Delay and make the next backend request recursively
          setTimeout(() => {
            this.checkSessionStatus();
          }, this.callingInterval);
        }
      }
    );
  }

  /**
   * Checks if the session status is in a final state and set up redirectUrl
   * @param session Payment instrument verification session
   */
  isSessionFinalStatus(session: InstrumentVerification): boolean {
    if (session.sessionStatus === InstrumentVerificationStatus.COMPLETED
        || session.sessionStatus === InstrumentVerificationStatus.FAILED) {
      if (session.returnUrl) {
        this.redirectUrl = session.returnUrl;
      }
      return true;
    }

    return false;
  }
}
