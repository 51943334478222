import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { OptimizelyFlagDecisionManagementService } from 'paysafe-wallet-feature-management-lib-fe';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from './components/components.module';
import { NotFoundNotificationComponent } from './components/exception-handling/not-found-notification/not-found-notification.component';
import { ConfigToken, EnvConfig } from './config/env.config';
import { BROWSER_FAVICONS_CONFIG } from './config/favicon.config';
import { RouterEventsComponent } from './router-events.component';
import { AuthInterceptor } from './service/interceptors/auth/auth.interceptor';
import { PlaidModule } from './service/plaid/plaid.module';


const optimizelyFactory = (config: EnvConfig): OptimizelyFlagDecisionManagementService =>
  new OptimizelyFlagDecisionManagementService(config.OPTIMIZELY_FEATURE_MANAGEMENT);

@NgModule({
  declarations: [
    AppComponent,
    NotFoundNotificationComponent,
    RouterEventsComponent
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PlaidModule,
    ComponentsModule,
    LoggerModule.forRoot({
      serverLogLevel: NgxLoggerLevel.OFF,
      level: NgxLoggerLevel.DEBUG
    })],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: OptimizelyFlagDecisionManagementService,
      useFactory: optimizelyFactory,
      deps: [ConfigToken]
    },
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: BROWSER_FAVICONS_CONFIG,
      useValue: {
        XSOLLA: {
          type: 'image/png',
          href: '../assets/favicons/xsolla.png'
        },
        BUSINESS_WALLET: {
          type: 'image/png',
          href: '../assets/favicons/paysafe.png'
        }
      }
    }
  ] })
export class AppModule { }
