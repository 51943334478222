import { Inject, Injectable } from '@angular/core';

import { BROWSER_FAVICONS_CONFIG } from '../../config/favicon.config';
import { Favicon } from '../../models/favicon/favicon';
import { FaviconsConfig } from '../../models/favicon/favicons-config';

@Injectable({
  providedIn: 'root'
})
export class FaviconService {

  private readonly faviconsConfig: FaviconsConfig;

  constructor(@Inject(BROWSER_FAVICONS_CONFIG) faviconsConfig: FaviconsConfig) {
    this.faviconsConfig = faviconsConfig;
  }

  setFaviconPerBrand(brand: string): void {
    const brandFavicon: Favicon = this.faviconsConfig[brand.toUpperCase()];

    if (!brandFavicon) {
      return;
    }

    this.removeOldFavicons();
    this.setFavicon(brandFavicon);
  }

  private removeOldFavicons(): void {
    const existingFavicons = document.querySelectorAll('link[rel="icon"]');
    existingFavicons.forEach(favicon => favicon.remove());
  }

  private setFavicon(favicon: Favicon): void {
    const faviconLink: HTMLLinkElement = document.createElement('link');
    faviconLink.rel = 'icon';
    faviconLink.type = favicon.type;
    faviconLink.href = favicon.href;

    document.head.appendChild(faviconLink);
  }
}
