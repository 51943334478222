import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import { StorageItem } from './storage-item.model';

/**
 * Service for processing the application (client-side)
 * storage using `localStorage` and by setting optional expiration time.
 */
@Injectable()
export class StorageService {
  constructor(private log: NGXLogger) {}

  /**
   * Set an item in the storage.
   * By default it sets a timestamp to the item.
   * @param key The item's key
   * @param value The item's value
   * @returns void
   *
   * @example
   * this.storageService.setItem<IModel>('key', value);
   */
  setItem<T>(key: string, value: T, expiration?: number): void {
    this.validate(key, 'setItem', value);
    const data: StorageItem<T> = {
      data: value,
      createdAt: Date.now(),
      expiration
    };
    localStorage.setItem(key, JSON.stringify(data));
  }

  /**
   * Get the actual stored data for given key
   * @param key The item's key
   *
   * @returns the stored value for the given key
   */
  getItem<T>(key: string): T | null {
    const item = this.getItemWithMetadata<T>(key);
    return item?.data ?? null;
  }

  /**
   * Check if item value is stored.
   *
   * @param key
   */
  hasItem(key: string): boolean {
    return !!this.getItem(key);
  }

  /**
   * Get an item value from the storage.
   * @param key The item's key
   * @returns StorageItem<T>
   *
   * @example
   * this.storageService.getItem<IModel>('key');
   */
  getItemWithMetadata<T>(key: string): StorageItem<T> | null {
    this.validate(key, 'getItemWithMetadata');
    let data: StorageItem<T> | null;
    try {
      const itemValue = localStorage.getItem(key);
      data = itemValue ? (JSON.parse(itemValue) as StorageItem<T>) : null;
    } catch {
      this.log.error(`[StorageService] Error parsing JSON data for key: ${key}`);
      data = null;
    }

    if (data?.expiration && this.isExpired(data)) {
      this.removeItem(key);
      return null;
    }

    return data;
  }

  /**
   * Delete an item in the storage
   * @param key The item's key
   * @returns void
   *
   * @example
   * this.storageService.removeItem('key');
   */
  removeItem(key: string): void {
    this.validate(key, 'removeItem');
    localStorage.removeItem(key);
  }

  /**
   * Delete all items in the storage
   * @returns void
   *
   * @example
   * this.storageService.clear();
   */
  clear(): void {
    localStorage.clear();
  }

  /**
   * Returns the name of the nth key in the list,
   * or null if n is greater than or equal to the number of key/value pairs in the object.
   * @param index The item's index in the storage
   * @returns string
   *
   * @example
   * const name: string = this.storageService.keyName(0); // Takes the first item in the storage
   */
  keyName(index: number): string | null {
    return localStorage.key(index);
  }

  /**
   * Number of items in the storage
   *
   * @example
   * const length = this.storageService.size;
   */
  get size(): number {
    return localStorage.length;
  }

  /**
   * Determines if the specific storage item is expired.
   * @param value Current storage item
   * @returns boolean
   *
   * @example
   * this.storageService.isExpired<IModel>(model);
   */
  private isExpired<T>(value: StorageItem<T>): boolean {
    if (!value.expiration) {
      return true;
    }
    const now = Date.now();

    return new Date(value.expiration).getTime() + value.createdAt < now;
  }

  // Validate required key and value storage properties
  private validate<T>(key: string, methodName: string, value?: T): void {
    if (!key) {
      throw new Error(`[StorageService] Storage key parameter is required in ${methodName} method!`);
    }

    const check = typeof value === 'boolean' ? value == null : !value;
    if (check && methodName === 'setItem') {
      throw new Error(`[StorageService] Storage value parameter is required in ${methodName} method!`);
    }
  }
}
