import { Injectable } from '@angular/core';

import { PlaidHandler } from '../../../models/plaid/plaid-handler';

/*
 * Review this later as it's subject of further refactoring and possibly
 * event handlers might be moved here if appears to make sense.
 */

@Injectable()
export class PlaidHandlerService {
  private handler?: PlaidHandler;

  init(plaidHandler: PlaidHandler): void {
    this.handler = plaidHandler;
  }

  /**
   * Show plaid dialog
   */
  open(): void {
    this.handler?.open();
  }
}
