import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, tap } from 'rxjs';

import { LINK_TOKEN_KEY, SESSION_ID_KEY } from './config/constants';
import { PlaidParameters } from './models/plaid/plaid-parameters';
import { FaviconService } from './service/favicon/favicon.service';
import { PlaidDialogService } from './service/plaid/dialog/plaid-dialog.service';
import { SecureStorageService } from './service/storage/secure-storage.service';
import { StorageService } from './service/storage/storage.service';
import { extractJwtProperty } from './util/utility-functions';

@Component({
  selector: 'app-router-events',
  template: '',
})
export class RouterEventsComponent implements OnInit {

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly plaidDialogService: PlaidDialogService,
    private readonly storageService: StorageService,
    private readonly secureStorageService: SecureStorageService,
    private readonly faviconService: FaviconService,
  ) {
  }

  ngOnInit(): void {
    const sid = this.storageService.getItem(SESSION_ID_KEY);
    const queryParams = this.route.snapshot.queryParams;
    const oauthStateId = queryParams.oauth_state_id as string;

    this.setFavicon();

    if (oauthStateId) {
      this.processOauthRedirect();
    } else if (sid) {
      this.initializePlaidDialog(false);
    } else {
      void this.router.navigate(['not-found'], { relativeTo: this.route });
    }
  }

  initializePlaidDialog(usedSavedLinkToken: boolean, token?: string): void {
    const info: PlaidParameters = {
      usedSavedLinkToken,
      token
    };

    this.plaidDialogService.initPlaid(info)
      .pipe(
        filter(x => x),
        tap(() => this.plaidDialogService.show())
      )
      .subscribe();
  }

  private processOauthRedirect(): void {
    const isRedirectFromThirdParty = true;
    const lastUsedLinkToken: string | null = this.storageService.getItem<string>(LINK_TOKEN_KEY);
    if (lastUsedLinkToken) {
      // Init Plaid for the last instrument, that was attempted for verification. Plaid itself will continue the user's session.
      this.initializePlaidDialog(isRedirectFromThirdParty, lastUsedLinkToken);
    } else {
      // Trigger again the flow for adding bank account.
      // Plaid itself will continue the user's session.
      // this.storageService.removeItem(...);
      this.initializePlaidDialog(isRedirectFromThirdParty);
    }
    this.storageService.removeItem(LINK_TOKEN_KEY);
  }

  private setFavicon(): void {
    const accessToken: string | undefined = this.secureStorageService.getAccessToken();
    const brand: string = accessToken ? extractJwtProperty(accessToken, 'brand') : '';

    this.faviconService.setFaviconPerBrand(brand);
  }
}
