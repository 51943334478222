import { Injectable } from '@angular/core';

import { getCookie } from '../../util/utility-functions';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class SecureStorageService {
  XSRF_COOKIE_KEY = 'XSRF-TOKEN';
  ACCESS_TOKEN_COOKIE_KEY = 'accessToken';

  constructor(private authService: AuthService) {
  }

  getXsrfToken(): string | undefined{
    const xsrfCookieValue: string = getCookie(this.XSRF_COOKIE_KEY);

    if (xsrfCookieValue) {
      return xsrfCookieValue;
    }

    return this.authService.getXsrfToken();
  }

  getAccessToken(): string | undefined{
    const accessTokenCookieValue: string = getCookie(this.ACCESS_TOKEN_COOKIE_KEY);

    if (accessTokenCookieValue) {
      return accessTokenCookieValue;
    }

    return this.authService.getAccessToken();
  }
}
