import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';

import { SESSION_ID_KEY } from '../../config/constants';
import { ConfigToken, EnvConfig } from '../../config/env.config';
import { InstrumentVerification } from '../../models/plaid/instrument-verification/plaid-instrument-verification';
import { PlaidVerificationContext } from '../../models/plaid/plaid-verification-context';
import { StorageService } from '../storage/storage.service';
import { BackendError } from './error/backend.error';

@Injectable()
export class PlaidService {
  config: EnvConfig;
  PLAID_VERIFICATION_URL = 'api/v1/instruments/verifications/{sessionId}';
  VERIFICATION_CONTEXT_SUFFIX = '/verification-context';

  constructor(
    private readonly http: HttpClient,
    @Inject(ConfigToken) config: EnvConfig,
    private readonly storageService: StorageService
  ) {
    this.config = config;
  }

  getEndpointUrl(): string {
      return `${this.config.BACKEND_URL}${this.PLAID_VERIFICATION_URL
        .replace('{sessionId}', this.storageService.getItem(SESSION_ID_KEY) ?? '')}`;
  }

  getInstrumentVerificationDetails(): Observable<InstrumentVerification> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      }),
      withCredentials: true
    };

    return this.http.get<InstrumentVerification>(this.getEndpointUrl(), httpOptions);
  }

  /**
   * Get verification context (get link token)
   */
  getPlaidVerificationContext(): Observable<PlaidVerificationContext> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      }),
      withCredentials: true
    };

    return this.http.get<PlaidVerificationContext>(this.getEndpointUrl() + this.VERIFICATION_CONTEXT_SUFFIX,
      httpOptions
    ).pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  }

  private handleError(error: HttpErrorResponse): Observable<PlaidVerificationContext> {
    return throwError(() => new BackendError('Backend Error', error));
  }

  updatePlaidVerificationContext(verificationContext: PlaidVerificationContext): Observable<PlaidVerificationContext> {
    return this.http.put<PlaidVerificationContext>(this.getEndpointUrl() + this.VERIFICATION_CONTEXT_SUFFIX,
      verificationContext, { withCredentials: true });
  }
}
