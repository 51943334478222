export function getCookie(name: string): string {
  const cookies: string[] = document.cookie.split(';');
  const cookieName = `${name}=`;

  for (const cookie of cookies) {
    const c = cookie.trim();
    if (c.startsWith(cookieName)) {
      return c.substring(cookieName.length);
    }
  }

  return '';
}

export function extractJwtProperty(jwt: string, property: string): string {
  const payloadBase64 = jwt.split('.')[1];
  const payloadDecoded = Buffer.from(payloadBase64, 'base64').toString();
  const parsedJwt = JSON.parse(payloadDecoded) as Record<string, string>;

  return parsedJwt[property];
}
